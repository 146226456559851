.construction-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2f2f2;
  }
  
  .construction-content {
    text-align: center;
  }
  
  .construction-content h1 {
    font-size: 32px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .construction-content p {
    font-size: 18px;
    color: #666;
  }
  