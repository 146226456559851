.entre-header {
    position: fixed;
    width: 100%;
    z-index: 999;
    top: 0;
    left: 0;
    -webkit-box-shadow: 0px 3px 30px -3px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 3px 30px -3px rgba(0,0,0,0.3);
    box-shadow: 0px 3px 30px -3px rgba(0,0,0,0.3);
    background-color: #fff;
}
.entre-header-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    min-width: 100%;
    padding: 10px 0px;
}
.entre-header .entre-header-wrap .logo-wrap {
    width: 15%;
}

.entre-header .entre-header-wrap .logo-wrap img {
    width: 100%;
    min-width: 100%;
    height: auto;
}
.entre-header .entre-header-wrap .logo-wrap span {
    display: block;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    margin-top: -1rem;
}
.entre-header .entre-header-wrap .header-menu {
    position: absolute;
    display: flex;
    align-items: center;
    right: 1vw;
    width: 15%;
    min-width: 150px;
}

.entre-header .entre-header-wrap .header-menu .user-img-wrap {
    width: 25%;
}

.entre-header .entre-header-wrap .header-menu .user-img-wrap img {
    max-width: 100%;
    height: auto;
}

.entre-header .entre-header-wrap .header-menu .user {
    width: 75%;
    padding-right: 0.5rem;
}

.entre-header .entre-header-wrap .header-menu .user p {
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-align: right;
}

.entre-header .entre-header-wrap .header-menu .user a {
    display: block;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-weight: 400;
    text-align: right;
    width: 100%;
}