.padding {
  padding-bottom: 0 !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  /* font-size: 12px !important; */
  padding-left: 2px;
  font-weight: bold !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  text-align: center;
}

.css-i4bv87-MuiSvgIcon-root {
  font-size: 22px !important;
  /* padding-right: 3px !important; */
}

.background {
  background-color: #191173;
  border-radius: 5px;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  background-color: white !important;
  padding: 0 !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 5 !important;
  padding-left: 5px !important;
}

.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
  margin: 0 !important;
  padding: 0 !important;
}

.center {
  display: flex !important;
  justify-content: center !important;
}

.total {
  /* background-color:  #0A062E; */
  /* color: white; */
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  /* border-radius: 5%; */
}

.button {
  width: 0.8em !important;
  margin-right: 5px !important;
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.css-1iq0dgj-MuiButtonBase-root-MuiIconButton-root{
    padding: 0 !important
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
  text-align: center;
  font-weight: bold !important;
}

.textfieldd{
  text-align: center !important;
  font-size: 14px;
}