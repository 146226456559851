.filter-search .filter-search-wrap {
    display: flex;
    padding: 2rem;
    border-radius: 10px;
    -webkit-box-shadow: 0px 3px 30px -3px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 3px 30px -3px rgba(0,0,0,0.3);
    box-shadow: 0px 3px 30px -3px rgba(0,0,0,0.3);
}
.filter-search .filter-search-wrap .search-box {
    width: 35%;
}
.filter-search .filter-search-wrap .search-box .input-search-wrap {
    position: relative;
}
.filter-search .filter-search-wrap .select-box {
    display: flex;
    flex-direction: column;
    width: 20%;
}
.filter-search-wrap .select-box .select-wrap {
    position: relative;
    display: grid;
    grid-template-areas: "select";
    align-items: center;
    width: 80%;
    border-radius: 10px;
    padding: 6.5px 10px;
    background-color: #f1f0f0;
    margin-top: 10px;
}

.filter-search-wrap .select-box .select-wrap:after {
    content: "";
    width: 0.8em;
    height: 0.5em;
    background-color: #191173;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    justify-self: end;
    margin: 10px;
}
select,
.select-box .select-wrap:after {
  grid-area: select;
}

.filter-search .filter-search-wrap .select-box select {
    width: 100%;
    border: none;
    appearance: none;
    background-color: transparent;
    text-align: left;
}

.select-box select,
.select-box label,
.search-box label,
.search-box input {
    font-family: Poppins, sans-serif;
    font-size: 14px;
}

.select-box select::-ms-expand {
    display: none;
}

.filter-search .filter-search-wrap .search-box .input-search-wrap input {
    width: 80%;
    background-color: #f1f0f0;
    border: none;
    border-radius: 10px;
    padding: 10px 10px 10px 40px;
    margin: 10px 0px;
}

.filter-search .filter-search-wrap .search-box .input-search-wrap i.search-icon {
    width: 20px;
    height: 20px;
    background-image: url(../../public/images/search-ico.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 10px;
    z-index: 999;
    top: calc(50% - 10px);
}

.filter-search .filter-search-wrap .filter-wrap {
    display: flex;
    align-items: center;
    width: 5%;
}

.filter-search .filter-search-wrap .filter-wrap .btn{
    width: 100%;
    padding: 10px 0px;
    border: none;
    cursor: pointer;
}
.btn.btn-filter {
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 2px;
    color: #fff;
    background-color: #191173;
    border-radius: 10px;
}