.name-selector {
  position: relative;
  display: inline-block;
  width: 140px;
  height: 20px;
  border-radius: 4px;
  overflow: hidden;
}

select {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  color: #050505;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  appearance: none;
}

select:focus {
  outline: none;
}

.option {
  background-color: #222222;
}

.option:hover {
  background-color: #444444;
}

.select-arrow {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 100%;
  background-color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-arrow svg {
  color: #ffffff;
  font-size: 14px;
}
