@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
select:focus-visible {
  outline: none;
}
body {
  margin: 0;
}

h1,
h2 {
  margin: 0;
}
p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

section {
  width: 98%;
  margin: auto;
  margin-bottom: 15px;
}

section:first-of-type {
  padding-top: 15vh;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
li {
  font-family: Poppins, sans-serif;
  margin: 0;
  cursor: default;
}
li {
  list-style: none;
}

.tab-col-0-36 {
  width: 0.4%;
  max-width: 0.4%;
  min-width: 0.4%;
}

.tab-col-1 {
  width: 1%;
  max-width: 1%;
}

.tab-col-2 {
  width: 2%;
  max-width: 2%;
}

.tab-col-3 {
  width: 3%;
  max-width: 3%;
}

.tab-col-3-5 {
  width: 3.5%;
  max-width: 3.5%;
  min-width: 3.5%;
}

.tab-col-5 {
  width: 5%;
  max-width: 5%;
}

.tab-col-7 {
  width: 7%;
  max-width: 7%;
  min-width: 7%;
}

.tab-col-7-1 {
  width: 8.3%;
  max-width: 8.3%;
  min-width: 8.3%;
}

.tab-col-9 {
  width: 9%;
  max-width: 9%;
  min-width: 9%;
}

.tab-col-10 {
  width: 10%;
  max-width: 10%;
  min-width: 10%;
}

.tab-col-15 {
  width: 15%;
  max-width: 15%;
}

.tab-col-20 {
  width: 20%;
  max-width: 20%;
  min-width: 20%;
}

.tab-col-30 {
  width: 30%;
  max-width: 30%;
  min-width: 30%;
}

.tab-col-33 {
  width: 33%;
  max-width: 33%;
}

.tab-col-40 {
  width: 40%;
  max-width: 40%;
}

.tab-col-60 {
  width: 60%;
  max-width: 60%;
}
.tab-col-70 {
  width: 70%;
  max-width: 70%;
}

.header-item-wrap {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.flex {
  background-color: white !important;
}
