/* .table-header,
.table-line {
    padding-left: 3%;
    padding-right: 3%;
} */
.table-header .header-item:first-child,
.table-line .table-item:first-child {
    flex: 1;
    text-align: left;
    justify-content: flex-start;
}
/* Estilos para o modal */
.custom-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 400px;
    max-width: 90%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  /* Estilos para o sombreamento de fundo */
  .custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  