@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.center {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  /* color: #fff; */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 3px;
}

.backgroundcolor {
  font-family: Poppins, sans-serif;
  background-color: transparent !important;
  color: black;
  /* font-weight: bold; */
}

.p {
  font-family: Poppins, sans-serif;

  width: 100%;
  border: none;
  appearance: none;
  text-align: center;
  display: none;
  grid-area: select;
  position: relative;
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  width: 100%;
  border-radius: 10px;
  padding: 6.5px 10px;
  font-size: 14px;
  max-width: 100%;
  text-overflow: ellipsis;
}

.same-total{
  font-weight: bold !important;
  color:red !important;
  font-size: 16px !important;
}

.totalpos{
  font-size: 16px !important;
  font-weight: bold !important;
  color:blue !important;

}

.tabelaPriority{
  padding-top: 2vh !important
}