/* TABLE HOME */
.table-header {
    background-color: #191173;
    padding: 10px 0px;
    border-radius: 10px;
}
.table-line {
    background-color: #f1f0f0;
    border-radius: 10px;
    margin: 3px 0px;
}

.table-header,
.table-line {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.table-header ul,
.table-line ul {
    display: flex;
}

.table-header ul li.header-item,
.table-line ul li.table-item {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    list-style: none;
    padding: 3px;
}

.table-header ul li.header-item {
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    /* text-align: center; */
}

.table-line ul li.table-item a {
    font-family: Poppins, sans-serif;
    font-size: 14px;
    text-decoration: none;
    color: #191173;
    /* text-align: center; */
}
.table-item {
    position: relative;
}
.table-item p {
    font-family: Poppins, sans-serif;
    font-size: 14px;
}

.table-item p.descripton-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.table-item span.description-tooltips {
    display: none;
}
.table-item:hover span.description-tooltips {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #191173;
    width: 400px;
    padding: 10px;
    border-radius: 10px 15px;
    z-index: 999;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    color: #fff;
}

.table-item .select-wrap select {
    cursor: pointer;
    width: 100%;
    border: none;
    appearance: none;
    background-color: transparent;
    text-align: center;
}

.table-item .select-wrap select::-ms-expand {
    display: none;
}

.table-item .select-wrap {
    position: relative;
    display: grid;
    grid-template-areas: "select";
    align-items: center;
    width: 100%;
    border-radius: 10px;
    padding: 6.5px 10px;
    background-color: #fff;
}

.table-item .select-wrap:after {
    grid-area: select;
}

.preview {
    display: flex;
    flex-direction: row;
    justify-content: space-around!important;
}

.preview .table-previews {
    width: 20%;
}

.preview .table-previews img{
    width: 100%;
    height: auto;
    max-width: 80px;
}

/* TABLE HME FIM */

/* TABLE COMUNICACAO */

.table-header .header-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
}

.table-header .header-title h1{
    font-family: Poppins, sans-serif;
    font-size: 26px;
    color: #fff;
}

.table-header .header-title p{
    font-family: Poppins, sans-serif;
    font-size: 18px;
    color: #fff;
}

.col-title h4 {
    text-align: center;
    padding: 10px 0px;
}

.table-row {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    min-height: 60vh;
}
.comunication-table {
    width: 100%;

}
.comunication-table tr {
    display: flex;
    flex-direction: row;
}
.tr-header {
    margin: 30px 0px;
}
.comunication-table tr th {
    display: flex;
}
.comunication-table tr td {
    padding: 5px 8px;
    margin: 0px 5px 5px 0px;
}
.cicle-blue {
    display: block;
    width: 10px;
    min-width: 10px;
    height: 10px;
    min-height: 10px;
    background-color: #191173;
    border-radius: 50%;
    margin-right: 10px;
    align-self: center;
}

.gray-tab {
    background-color: #f1f0f0;
    -webkit-box-shadow: 0px 2px 8px -3px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 2px 8px -3px rgba(0,0,0,0.3);
    box-shadow: 0px 2px 8px -3px rgba(0,0,0,0.3);
}

.col-shadow {
    -webkit-box-shadow: 0px 2px 20px -3px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 2px 20px -3px rgba(0,0,0,0.3);
    box-shadow: 0px 2px 20px -3px rgba(0,0,0,0.3);
    border-radius: 10px;
    padding: 20px;
    height: 100%;
}
.col-interna {
    padding-top: 20px;
    padding-right: 20px;
}
.col-last {
    padding-top: 20px;
}
.table-area ul li {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 10px 0px;
    font-size: 14px;
}
.h-100 {
    height: 100%;
}
.h-50 {
    height: 50%;
}
.pr-2 {
    padding-right: 20px;
}
.pt-2 {
    padding-top: 20px;
}
.ptr-2 {
    padding-top: 20px;
    padding-right: 20px;
}

.rpc{
    font-size: 9px !important;
}