.card12 {
  display: flex;
  flex-direction: row;
}
.css-1v28g3g-MuiTypography-root {
  margin: 20px;
}

.selectButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

Typography {
  text-overflow: ellipsis;
}

.css-ahj2mt-MuiTypography-root {
  font-size: 18px !important;
  word-wrap: break-word;
}

.css-jihktx-MuiTypography-root {
  font-size: 16px !important;
}

.css-rcwp42-MuiTypography-root {
  font-size: 16px !important;
  margin-inline: 2px !important;
}

.css-mhc70k-MuiGrid-root {
  padding-left: 0;
  margin-left: 0 !important;
}

.css-1ql4z88-MuiTypography-root {
  font-size: 15px !important;
}

.typography {
  font-family: "Poppins", sans-serif !important;
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 0.875rem;
  color: white !important;
  font-weight: bolder;
  background-color: #191173;
  border-radius: 5px;
  margin-top: 5px !important;
  min-height: 6vh;
  align-items: center;
  margin-inline: 10em;
}

.titles {
  display: flex;
  justify-content: center;
  padding: 2px !important;
  padding-bottom: 15px;
  font-size: 0.875rem !important ;
  font-weight: bold !important  ;
  margin-inline: 2px !important;
}

.cardContent {
  min-height: 100px;
  max-width: 400px;
  min-width: 400px;
  max-height: 100px;
}
