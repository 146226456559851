.breadcrumbs-list {
    display: flex;
    flex-direction: row;
}
.breadcrumbs-list .breadcrumbs-item {
    list-style: none;
}

.breadcrumbs-list .breadcrumbs-item a{
    display: flex;
    text-decoration: none;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #000;
}
.breadcrumbs-list .breadcrumbs-item a.active{
    color: #000536;
}
.breadcrumbs-list .breadcrumbs-item a:hover{
    color: rgb(0, 72, 250);
}
.breadcrumbs-list .breadcrumbs-item:not(:last-child) a p::after {
    content: ' | ';
    padding: 0px 10px;
    color: #000536;
}