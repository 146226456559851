.tableHeader {
  background-color: #191173 !important;
  font-weight: bold;
  text-align: center;
  align-items: center !important;
  padding: 10px 0px;
  border-radius: 5px;
  color: white;
  min-width: 32vw !important;
  max-width: 32vw;
  /* position: absolute; */
}

.css-eqj3rm-MuiTableCell-root {
  text-align: center !important;
}

.tableRow {
  align-items: center !important;
  padding: 5px;
  height: 50px;
  min-height: 20px !important;
  /* padding-right: 15px; */
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis !important;
  display: flex;
  text-transform: uppercase;
  white-space: now-wrap;
  word-wrap: break-word;
  /* font-weight: bold; */
  display: flex;
  justify-content: center;
}

.deleteButton {
  display: flex;
  margin-left: 10px !important; 
}

.name {
  text-align: center !important;
}

.table-header .header-item:first-child {
  text-align: center;
}

.nome {
  display: flex;
  flex-direction: row;
}
.css-1ex1afd-MuiTableCell-root {
  align-items: center;
  display: flex;
  padding-inline: 0 !important;
}

table-line1 {
  width: 28.5vw !important;
  height: 70px !important;
  min-width: 28vw !important;
  max-width: 28.5vw !important;
}

table-wrap {
  min-width: 100% !important;
  width: 100% !important;
}

.headers {
  display: flex;
  text-align: center !important;
  justify-content: center !important;
  font-size: 14px;
}

.css-jtxlei-MuiPaper-root-MuiCard-root {
  max-width: 33vw;
  min-width: 33vw;
  margin: 0 !important;
}

.customTableCell {
  width: 33% !important;
  min-width: 33% !important;
  max-width: 33% !important;
}

.css-1fau5hk-MuiTableCell-root {
  padding-inline: 0 !important;
}

.css-apqrd9-MuiTableBody-root {
  min-width: 100vw;
  width: 100%;
}

.email {
  font-family: "Poppins", sans-serif !important;
  border: none !important;
}

.header-item {
  text-align: left;
}

tr.MuiTableRow-root {
  display: flex;
  align-items: center;
}

.css-1nz2pwh-MuiTableCell-root,
.css-1eshzj5-MuiTableCell-root,
.css-15cs3lv-MuiPaper-root-MuiCard-root,
.css-1rrknh2-MuiTableCell-root,
.css-18mfkcj-MuiTableCell-root,
.css-1r9tki7-MuiTableCell-root,
.css-q3pn83-MuiTableCell-root,
.css-1ex1afd-MuiTableCell-root,
.css-gbhfz2-MuiTableCell-root {
  border: none !important;
  padding: 0;
}

.css-1ex1afd-MuiTableCell-root {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.css-18mfkcj-MuiTableCell-root,
.css-1rrknh2-MuiTableCell-root,
.css-1wypzda-MuiTableCell-root {
  padding: 0 !important;
}

.MuiTableCell-root div {
  display: flex;
  justify-content: center;
}

.css-1g1fc3q-MuiCardContent-root {
  padding: 0 !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  text-transform: none !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 5px !important;
}

.css-1r9tki7-MuiTableCell-root,
.css-1eshzj5-MuiTableCell-root, .css-5tvn4b-MuiCardContent-root, .css-1qhmdpr-MuiCardContent-root {
  padding: 0 !important;
}

.name {
  padding-left: 20px;
}

.tableCell {
  align-items: center;
  display: flex;
  max-width: 100%;
  text-overflow: ellipsis;
  word-wrap: break-word;
  padding: 0;
  text-align: center !important;
  justify-content: center;
  border: none !important;
}

.editEmail{
  display: flex;
  flex-direction: row;
}

.emailButton .creatingNewRow{
  display: flex;
  flex-direction: column;
}