@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");



.divCentral {
  display: flex;
  flex-direction: row !important;
  justify-content: center !important;
  align-content: center;
  text-align: center;
  background-color: white;
  margin-top: 30px
}

.css-1x5jdmq{
  background-color: #f1f0f0 !important;
}

.MuiDataGrid-columnHeaderTitleContainer {
  background-color: #191173; /* Cor de fundo do cabeçalho da tabela */
  color: white; /* Cor do texto do cabeçalho da tabela */
  padding-left: 0 !important;
  border-radius: 5px;
  font-weight: bold;
  padding-inline: 0;
}
.css-12u49z-MuiDataGrid-root .MuiDataGrid-columnHeader {
  padding-left: 0 !important;
}

.css-t89xny-MuiDataGrid-columnHeaderTitle {
  padding-left: 5px;
  font-size: 14px;
  font-weight: bold !important;
  font-family: Poppins, sans-serif;
}

.MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center !important;
  border-radius: 5px;
}

.css-12u49z-MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell {
  font-weight: bold !important;
  font-size: 14px;
}
.css-12u49z-MuiDataGrid-root .MuiDataGrid-cellContent {
  font-size: 15px;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 16px !important;
  justify-content: center !important;
  align-items: center;
  align-content: center;
  text-align: center;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  justify-content: center !important;
  font-weight: bold !important;
}

.legendaselect {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: center;
  align-content: center;
  text-align: center;
  padding-bottom: 30px;
  padding-inline: 50px;
}

.css-12u49z-MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell {
  padding: 0;
}
.css-12u49z-MuiDataGrid-root .MuiDataGrid-cellContent {
  padding-left: 20px;
}

.salvarBtn {
  display: flex;
  text-align: center;
  padding-left: 20px;
  margin-left: 30px
}

MuiDataGrid-columnHeaderTitle css-mh3zap{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold !important;
  font-family: Poppins,sans-serif;
}

.MuiDataGrid-columnHeaderTitle .css-mh3zap{
text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap;
font-weight: bold !important;
font-family: Poppins,sans-serif;
}

.css-mh3zap{
  text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: bold !important;
    font-family: Poppins,sans-serif;
}

/* h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
li {
  font-family: Poppins, sans-serif;
  margin: 0;
} */

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.css-1lymaxv-MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell {
  background-color: #f1f0f0;
}
.MuiDataGrid-withBorderColor {
  border-color: white !important;
  border-style: solid;
}

.css-1lymaxv-MuiDataGrid-root .MuiDataGrid-cellContent {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  text-decoration: none;
  color: #191173;
  padding-left: 30px;
  align-items: justify;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-right: 32px;
  font-size: 15px;
}

.legendaNotAdmin{
display: flex;
flex-direction: row;
width: 100%;
justify-content: space-evenly;
/* margin: 30px */
margin-top: 30px;
margin-bottom: 30px

}

.itemLegenda{
  border-radius: 3px;

}

.R{
  color: white;
  background-color: #2e75b6;
  padding-inline: 30px;
  /* text-shadow: 0 0 0.2em rgb(255, 255, 255), 0 0 0.2em rgb(115, 153, 228) !important; */
}

.A{
  background-color: #bdd7ee;
  padding-inline: 30px;
  text-shadow: 0 0 0.2em rgb(255, 255, 255), 0 0 0.2em rgb(115, 153, 228) !important;
}

.C{
  color: white;
  background-color: #1d8cf7;
  padding-inline: 30px;
  /* text-shadow: 0 0 0.2em rgb(255, 255, 255), 0 0 0.2em rgb(115, 153, 228) !important; */

}

.I{
  background-color: #adb9ca;
  padding-inline: 30px;
  text-shadow: 0 0 0.2em rgb(255, 255, 255), 0 0 0.2em rgb(115, 153, 228) !important;
}

 A C I R span{
  padding-left: 5px;

 }

 .css-1lymaxv-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell{
  padding-left: 0 !important;
  padding-right: 0;
  border-radius: px;
  /* margin-left: 5px; */
  width: 100% !important;
 }

 .css-1lymaxv-MuiDataGrid-root .MuiDataGrid-columnHeader{
  padding-inline: 0 !important;
 }

 .css-1lymaxv-MuiDataGrid-root .MuiDataGrid-withBorderColor{
  border-radius: 5px;
 }

 .tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: black;
  color: white;
  padding: 4px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 1;
}
