/* FONTE */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* css login */
.container {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-image:url(../public/backgroundImage.jpeg); */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.grid-1-login {
  background-image: url(../public/images/backgroundPmovil.jpg);
  background-size: cover;
}

.box {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

/* a:hover {
  background-color: yellow;
} */

.inputLogin {
  width: 100%;
}

.css-0 {
  width: 40%;
}

@media (max-width: 600px) {
  .css-0 {
    width: 80%;
  }
}

/* css home  */

.MuiInputBase-input {
  /* padding: 10px 10px 10px 10px !important; */
}

.containerHome {
  width: 90vw;
  margin: auto;
  /* background-repeat: no-repeat;
  background-size: cover; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.boxHome {
  /* box-shadow: #002966 0px 0px 0px 3px; */
  width: 100%;
  min-height: 100%;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  margin-bottom: 10vh;
}

.inputHome {
  width: 200px;
}

.h-divider {
  margin: auto;
  margin-top: 80px;
  width: 95%;
  position: relative;
}

.h-divider .shadow {
  overflow: hidden;
  height: 20px;
}

.h-divider .shadow:after {
  content: "";
  display: block;
  margin: -25px auto 0;
  width: 100%;
  height: 25px;
  border-radius: 125px/12px;
  box-shadow: 0 0 8px #000536;
}

.headlineStyle {
  margin-left: 50px;
  color: #000536;
  font-weight: bolder !important;
  text-align: center;
}

th {
  color: #000536 !important;
  font-weight: bolder !important;
}

.color-input-wrapper {
  height: 1.2em;
  width: 1.2em;
  overflow: hidden;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  position: relative;
  border: solid 1px grey;
  vertical-align: inherit;
}

.color-input-wrapper input[type="color"] {
  position: absolute;
  height: 4em;
  border-color: black;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  border: none;
  margin: 0;
  padding: 0;
}

.td-size {
  display: flex;
  align-items: center;
  min-height: 25vh;
  background: #e7e9eb;
  height: 100%;
}

.td-size p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  word-break: break-word;
}

.left-td-p {
  padding: 0px 16px 0px 16px;
}

.just-center {
  justify-content: center;
}

.td-size a,
.td-size a:hover {
  font-family: "Poppins", sans-serif;
  color: #000;
  text-decoration: none;
  font-weight: 600;
}

tr.MuiTableRow-root {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.MuiTableCell-root {
  overflow: hidden;
  position: relative;
}

.MuiTableCell-root div {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #000;
}

.MuiSelect-select {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  color: #000 ;
}

/* .fixed-table {
  padding-top: 40vh;
} */

/* .align-with-head:first-child {
  padding-top: 11vh;
}
 */
/* @media(min-width: 1400px) {
  .fixed-table {
    padding-top: 35vh;
  }

  .align-with-head:first-child {
    padding-top: 8vh;
  }
} */

/* @media(min-width: 1650px) {
  .fixed-table {
    padding-top: 32vh;
  }

  .align-with-head:first-child {
    padding-top: 7vh;
  }
} */

/* corrige td gerado sem identificação de tableCell */

.css-1m7vqty-MuiTableCell-root {
  border-bottom: none !important;
  width: 100% !important;
}

/*select home status*/
.status {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/*cards tabela Comunicação*/

.Cards {
  display: flex;
  flex-direction: row;
  padding-top: 50px;
  /* background: #F1F0F0; */
  padding-bottom: 50px;
}

.css-1dw4nt7-MuiPaper-root-MuiCard-root {
  background-color: #f1f0f0 !important;
  border: #f1f0f0 !important;
  min-height: 320px !important;
}

body {
  font-family: "Poppins", sans-serif;
}


.css-154io4v-MuiTypography-root {
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  font-weight: bold;
  padding-top: 20px;
  font-size: 0.875rem;
  padding-bottom: 20px;
  color: #000536 !important;
  font-weight: bolder;
}

/*tabela dentro do card*/
/* tr.MuiTableRow-root{
  display: table-row !important
}

.css-r2wg9v-MuiCardContent-root{
  width: 50%;
  padding-left: 95p;
}
 */

.css-uk3gxw-MuiTableCell-root {
  padding: 2px !important;
}

.css-1dw4nt7-MuiPaper-root-MuiCard-root {
  margin: 3px !important;
}

/* tr.MuiTableRow-root{
  display: table-row;
  padding-top: 20px;
 } */

.tabela {
  display: table-row;
  padding-top: 20px;
}

.css-13qkvwv-MuiGrid-root {
  padding-top: 0 !important;
}

.css-11lq3yg-MuiGrid-root {
  display: inline-table !important;
}

.MuiTableCell-root div {
  margin-top: 0;
}

/* accordion Responsabilidade */
.accordionPrincipal {
  padding-top: 10px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 600px;
}

Accordion {
  height: 10px;
}

.color-selector {
  height: 20px;
}

name-selector {
  padding-left: 0;
  padding-right: 20px !important;
}

select {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  color: #050505;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  appearance: none;
}

.classeResp {
  display: flex !important;
  flex-direction: row !important;
  width: 95vw !important;
  height: 0;
}

.css-uk3gxw-MuiTableCell-root {
  text-align: center !important;
}
/* 
.css-1v28g3g-MuiTypography-root{
  margin: 20px!important
} */

/* .css-hxhtga-MuiTableCell-root, .css-11cw63i-MuiTableCell-root, .css-bprw9o-MuiTableCell-root  {
  background-color: white;
  margin-bottom: 10px;
  padding-top: 0 !important;
  padding-bottom: 10px !important;
  background-color: #f1f0f0;

} */

.bold{
  font-weight: bold;
}

.tituloResp{
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
}
.socialButton{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.microsoft-login-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #c0def5; 
  color: black; 
  text-decoration: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  align-items: center;
  text-align: center;
  display: flex;
}

.microsoft-login-button:hover {
  background-color: #469af4;
}
.textfield{
  height: 30px !important;
}