/* select{
    font-family: Poppins, sans-serif;
    font-size: 14px;
    justify-self: center;
} */

.select-arrow {
    width: 20px;
  }

.name-selector {
    display: initial;
    
}
.css-6qfsqn-MuiTableCell-root{
    padding-inline: 1px !important;
}    

.tableCell{
    min-width: 200px
}


.css-1wfg74j-MuiTableHead-root{
    padding: 10px;
}

.css-6qfsqn-MuiTableCell-root{
    padding: 0
}




.tituloResp{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    font-weight: bold;
    text-align: center !important;
}
.titulos{
    margin-top: 35px
}

